// React imports

// Remix imports
import { Form, useActionData } from '@remix-run/react'
import type {
  ActionFunction,
  HeadersFunction,
  LoaderFunction,
  MetaFunction,
} from '@remix-run/server-runtime'

// Third party imports
import gql from 'graphql-tag'
import MainTinyCentered from '~/components/containers/MainTinyCentered'

// Generated imports

// App imports
import NewsletterSignupForm from '~/components/forms/NewsletterSignupForm'
import { fetchGlobalGQL } from '~/fetch.server'
import type { NewsletterSignupViewMutation } from '~/generated/graphql'
import { getLang } from '~/i18n.universal'
import actionFormSubmit from '~/utils/actionFormSubmit.server'
import { CACHE_CONTROL } from '~/utils/cacheControl'
import getFormData from '~/utils/getFormData'
import type { GlobalDataAndTrans } from '~/utils/getGlobalDataAndTrans.server'
import getSEOFields from '~/utils/getSEOFields'
import { actionJson, loaderJson } from '~/utils/jsonResponse.server'
import redirect303 from '~/utils/redirect303.server'
import urlReverse from '~/utils/urlReverse'

const VIEW_CACHE = CACHE_CONTROL.ONE_HOUR

// ============================================================================
// HTML =======================================================================
// ============================================================================
export default function NewsletterSignupView() {
  const actionData = useActionData()
  return (
    <main>
      <MainTinyCentered>
        <NewsletterSignupForm
          FormElement={Form}
          actionData={actionData}
        />
      </MainTinyCentered>
    </main>
  )
}

// ============================================================================
// META =======================================================================
// ============================================================================
export const meta: MetaFunction = ({ data }) => {
  const globalData = data.global as GlobalDataAndTrans
  return getSEOFields(data, globalData.trans.SubscribeForTheLatestUpdates)
}

// ============================================================================
// LOADER =====================================================================
// ============================================================================
export let loader: LoaderFunction = async ({ request, params }) => {
  const lang = getLang(params)
  const { status, headers, global } = await fetchGlobalGQL(request, params)
  return loaderJson({ lang, global }, VIEW_CACHE, { status, headers })
}

// ============================================================================
// HEADERS ====================================================================
// ============================================================================
export const headers: HeadersFunction = () => {
  return {
    'Cache-Control': VIEW_CACHE,
  }
}

// ============================================================================
// ACTION =====================================================================
// ============================================================================
export const mutationName = 'newNewsletterSignup'
export const NEWSLETTER_SIGNUP_MUTATION = gql`
  mutation NewsletterSignupView($data: String) {
    newNewsletterSignup(data: $data) {
      status
      formErrors
    }
  }
`

export const action: ActionFunction = async ({ request, params }) => {
  const lang = getLang(params)
  const formDataObj = await getFormData(request)
  const { data, formErrors, formData, headers } =
    await actionFormSubmit<NewsletterSignupViewMutation>(
      request,
      params,
      formDataObj,
      NEWSLETTER_SIGNUP_MUTATION,
      mutationName
    )

  if (data.newNewsletterSignup?.status === 200) {
    return redirect303(urlReverse('newsletterSuccess', { lang }), headers)
  }

  return actionJson({ formErrors, formData }, { headers })
}
